import { render, staticRenderFns } from "./assetManage.vue?vue&type=template&id=e8f74530&scoped=true&"
import script from "./assetManage.vue?vue&type=script&lang=js&"
export * from "./assetManage.vue?vue&type=script&lang=js&"
import style0 from "./assetManage.vue?vue&type=style&index=0&id=e8f74530&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8f74530",
  null
  
)

export default component.exports